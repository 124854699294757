exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ar-more-than-just-style-js": () => import("./../../../src/pages/ar/more-than-just-style.js" /* webpackChunkName: "component---src-pages-ar-more-than-just-style-js" */),
  "component---src-pages-ar-more-than-just-style-thank-you-js": () => import("./../../../src/pages/ar/more-than-just-style-thank-you.js" /* webpackChunkName: "component---src-pages-ar-more-than-just-style-thank-you-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-backup-js": () => import("./../../../src/pages/contact-us-backup.js" /* webpackChunkName: "component---src-pages-contact-us-backup-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-custom-kitchens-js": () => import("./../../../src/pages/custom-kitchens.js" /* webpackChunkName: "component---src-pages-custom-kitchens-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchen-specialist-js": () => import("./../../../src/pages/kitchen-specialist.js" /* webpackChunkName: "component---src-pages-kitchen-specialist-js" */),
  "component---src-pages-kitchen-specialist-thank-you-js": () => import("./../../../src/pages/kitchen-specialist-thank-you.js" /* webpackChunkName: "component---src-pages-kitchen-specialist-thank-you-js" */),
  "component---src-pages-lp-thankyou-js": () => import("./../../../src/pages/lp-thankyou.js" /* webpackChunkName: "component---src-pages-lp-thankyou-js" */),
  "component---src-pages-more-than-just-style-js": () => import("./../../../src/pages/more-than-just-style.js" /* webpackChunkName: "component---src-pages-more-than-just-style-js" */),
  "component---src-pages-more-than-just-style-thank-you-js": () => import("./../../../src/pages/more-than-just-style-thank-you.js" /* webpackChunkName: "component---src-pages-more-than-just-style-thank-you-js" */),
  "component---src-pages-page-js": () => import("./../../../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */),
  "component---src-pages-page-not-found-js": () => import("./../../../src/pages/page-not-found.js" /* webpackChunkName: "component---src-pages-page-not-found-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-premium-german-kitchen-js": () => import("./../../../src/pages/premium-german-kitchen.js" /* webpackChunkName: "component---src-pages-premium-german-kitchen-js" */),
  "component---src-pages-premium-kitchen-js": () => import("./../../../src/pages/premium-kitchen.js" /* webpackChunkName: "component---src-pages-premium-kitchen-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-ramadan-js": () => import("./../../../src/pages/ramadan.js" /* webpackChunkName: "component---src-pages-ramadan-js" */),
  "component---src-pages-ramadan-promo-js": () => import("./../../../src/pages/ramadan-promo.js" /* webpackChunkName: "component---src-pages-ramadan-promo-js" */),
  "component---src-pages-ramadan-thank-you-js": () => import("./../../../src/pages/ramadan-thank-you.js" /* webpackChunkName: "component---src-pages-ramadan-thank-you-js" */),
  "component---src-pages-ramadanpromo-js": () => import("./../../../src/pages/ramadanpromo.js" /* webpackChunkName: "component---src-pages-ramadanpromo-js" */),
  "component---src-pages-ramadanthankyou-js": () => import("./../../../src/pages/ramadanthankyou.js" /* webpackChunkName: "component---src-pages-ramadanthankyou-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-subscription-thank-you-js": () => import("./../../../src/pages/subscriptionThank-you.js" /* webpackChunkName: "component---src-pages-subscription-thank-you-js" */),
  "component---src-pages-thank-you-career-js": () => import("./../../../src/pages/thank-you-career.js" /* webpackChunkName: "component---src-pages-thank-you-career-js" */),
  "component---src-pages-thank-you-enquiry-js": () => import("./../../../src/pages/thank-you-enquiry.js" /* webpackChunkName: "component---src-pages-thank-you-enquiry-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templetes-about-child-pages-js": () => import("./../../../src/templetes/AboutChildPages.js" /* webpackChunkName: "component---src-templetes-about-child-pages-js" */),
  "component---src-templetes-blog-category-page-js": () => import("./../../../src/templetes/BlogCategoryPage.js" /* webpackChunkName: "component---src-templetes-blog-category-page-js" */),
  "component---src-templetes-blog-detail-page-js": () => import("./../../../src/templetes/BlogDetailPage.js" /* webpackChunkName: "component---src-templetes-blog-detail-page-js" */),
  "component---src-templetes-custom-kitchen-design-js": () => import("./../../../src/templetes/CustomKitchenDesign.js" /* webpackChunkName: "component---src-templetes-custom-kitchen-design-js" */),
  "component---src-templetes-kitchen-details-page-js": () => import("./../../../src/templetes/KitchenDetailsPage.js" /* webpackChunkName: "component---src-templetes-kitchen-details-page-js" */),
  "component---src-templetes-portfoliodetail-js": () => import("./../../../src/templetes/portfoliodetail.js" /* webpackChunkName: "component---src-templetes-portfoliodetail-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/Footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-navbar-js": () => import("./../../../src/components/Navbar.js" /* webpackChunkName: "slice---src-components-navbar-js" */)
}

